import React, { useState } from "react";
import getActiveBanners from "../../../containers/graphql/queries/getActiveBanners";
import { useQuery } from "@apollo/client";
import { Carousel } from "antd";

export default function Banner() {
  const { loading, data } = useQuery(getActiveBanners);
  const [expandedBanner, setExpandedBanner] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  if (loading) {
    return <h1>Loading...</h1>;
  }

  const banners = data?.getActiveBanners?.data || [];

  const handleBannerClick = (item) => {
    setExpandedBanner(item);
  };

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target.id === "backdrop") {
      handleCloseExpanded();
    }
  };

  const handleCloseExpanded = () => {
    setExpandedBanner(null);
  };

  if (!isVisible) {
    return null;
  }
  return (
    <>
      {banners.length > 0 && (
        <div
          style={{
            width: "100%",
            padding: "0",
            marginBottom: "20px"
          }}
        >
          {expandedBanner ? (
            <div
              id="backdrop"
              onClick={handleBackdropClick}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "80%",
                  maxWidth: "800px",
                  backgroundColor: "transparent"
                }}
              >
                <button
                  onClick={handleCloseExpanded}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    border: "none",
                    padding: "10px",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                    backgroundColor: "transparent",
                    color: "yellow",
                    zIndex: 2000
                  }}
                >
                  X
                </button>
                <div style={{ position: "relative" }}>
                  <img
                    src={expandedBanner.image}
                    alt={expandedBanner.title}
                    style={{ width: "100%", borderRadius: "8px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      width: "100%",
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "20px",
                      borderRadius: "0 0 8px 8px",
                      textAlign: "center",
                      zIndex: 1500
                    }}
                  >
                    <h2>{expandedBanner.title}</h2>
                    <p>{expandedBanner.description}</p>
                    <a
                      href={expandedBanner.link}
                      style={{ color: "cyan", textDecoration: "underline" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join Game
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Carousel autoplay dots={false}>
                {banners.map((item) => (
                  <div key={item.id} onClick={() => handleBannerClick(item)}>
                    <h2
                      style={{
                        color: "black",
                        position: "relative",
                        textAlign: "center",
                        backgroundColor: "rgb(202, 195, 218)",
                        padding: "10px",
                        bottom: "10px",
                        top: "10px",
                        fontSize: "18px",
                        cursor: "pointer"
                      }}
                    >
                      {item.title}
                    </h2>
                  </div>
                ))}
              </Carousel>

              <button
                onClick={handleCancelClick}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "transparent",
                  color: "red",
                  border: "none",
                  padding: "8px 12px",
                  cursor: "pointer",
                  borderRadius: "4px"
                }}
              >
                X
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
