import gql from "graphql-tag";

export default gql`
  query {
    getActiveBanners {
      status
      message
      data {
        id
        title
        description
        image
        link
        status
      }
    }
  }
`;
